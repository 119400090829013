<template>
  <div class="authInfoPage">
    <div class="top">
      <el-button type="primary" size="medium" @click="save('authForm')"
        >保存</el-button
      >
    </div>
    <!-- 表单区域 -->
    <el-form
      :model="authForm"
      :rules="authRule"
      ref="authForm"
      label-width="160px"
    >
      <div class="content">
        <el-form-item
          label="法人身份证(正面)"
          prop="legalPersonIDCardOn.picUrl"
        >
          <UploadImg
            :loading="authForm.legalPersonIDCardOn.loading"
            :uploaded="authForm.legalPersonIDCardOn.uploaded"
            :picUrl="authForm.legalPersonIDCardOn.picUrl"
            @update:file="updateFile"
            @change="uploadLegalPersonIDCardOn(authForm.legalPersonIDCardOn)"
            @deleteImg="deleteImg(authForm.legalPersonIDCardOn)"
          ></UploadImg>
        </el-form-item>
        <el-form-item
          label="法人身份证(反面)"
          prop="legalPersonIDCardBack.picUrl"
        >
          <UploadImg
            :loading="authForm.legalPersonIDCardBack.loading"
            :uploaded="authForm.legalPersonIDCardBack.uploaded"
            :picUrl="authForm.legalPersonIDCardBack.picUrl"
            @update:file="updateFile"
            @change="
              uploadLegalPersonIDCardBack(authForm.legalPersonIDCardBack)
            "
            @deleteImg="deleteImg(authForm.legalPersonIDCardBack)"
          ></UploadImg>
        </el-form-item>
        <el-form-item
          label="营业执照(原件三合一正本)"
          prop="businessLicense.picUrl"
        >
          <UploadImg
            :loading="authForm.businessLicense.loading"
            :uploaded="authForm.businessLicense.uploaded"
            :picUrl="authForm.businessLicense.picUrl"
            @update:file="updateFile"
            @change="uploadBusinessLicense(authForm.businessLicense)"
            @deleteImg="deleteImg(authForm.businessLicense)"
          ></UploadImg>
        </el-form-item>
        <el-form-item label="道路经营许可证" prop="rTBusinessLicense.picUrl">
          <UploadImg
            :loading="authForm.rTBusinessLicense.loading"
            :uploaded="authForm.rTBusinessLicense.uploaded"
            :picUrl="authForm.rTBusinessLicense.picUrl"
            @update:file="updateFile"
            @change="uploadRtBusinessLicense(authForm.rTBusinessLicense)"
            @deleteImg="deleteImg(authForm.rTBusinessLicense)"
          ></UploadImg>
        </el-form-item>
        <el-form-item label="企业银行卡" prop="legalPersonBankCard.picUrl">
          <UploadImg
            :loading="authForm.legalPersonBankCard.loading"
            :uploaded="authForm.legalPersonBankCard.uploaded"
            :picUrl="authForm.legalPersonBankCard.picUrl"
            @update:file="updateFile"
            @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
            @deleteImg="deleteImg(authForm.legalPersonBankCard)"
          ></UploadImg>
        </el-form-item>
        <el-form-item label="其他证件" prop="oherCertificate.picUrl">
          <UploadImg
            :loading="authForm.oherCertificate.loading"
            :uploaded="authForm.oherCertificate.uploaded"
            :picUrl="authForm.oherCertificate.picUrl"
            @update:file="updateFile"
            @change="uploadOherCertificate(authForm.oherCertificate)"
            @deleteImg="deleteImg(authForm.rTBusinessLicense)"
          ></UploadImg>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import UploadImg from "@/components/commonCmpt/uploadImg";
import {
  saveGoodsOwnerAuthInfo,
  getGoodsOwnerAuthInfo,
  uploadLegalPersonIDCardOn,
  uploadLegalPersonIDCardBack,
  uploadLegalPersonBankCard,
  uploadOherCertificate,
  uploadBusinessLicense,
  uploadConveyLicence,
} from "@/api/goodsOwner/goodsOwnerManage/authInfo";
export default {
  data() {
    return {
      currentFile: "", //当前正在操作的文件
      authForm: {
        userID: 0,
        //法人身份证(正面)
        legalPersonIDCardOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //法人身份证(反面)
        legalPersonIDCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //营业执照(原件三合一正本)照片
        businessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路经营许可证
        rTBusinessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //企业银行卡
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件照
        oherCertificate: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      authRule: {
        "legalPersonIDCardOn.picUrl": [
          {
            required: true,
            message: "请上传法人身份证(正面)",
            trigger: "change",
          },
        ],
        "legalPersonIDCardBack.picUrl": [
          {
            required: true,
            message: "请上传法人身份证(反面)",
            trigger: "change",
          },
        ],
        "businessLicense.picUrl": [
          {
            required: true,
            message: "请上传营业执照(原件三合一正本)",
            trigger: "change",
          },
        ],
        "rTBusinessLicense.picUrl": [
          {
            required: true,
            message: "请上传道路经营许可证",
            trigger: "change",
          },
        ],
        "legalPersonBankCard.picUrl": [
          { required: true, message: "请上传道企业银行卡", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["setFullInfo"]),
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            userID: 0,
            BusinessLicenseURL: this.authForm.businessLicense.picUrl,
            RtBusinessLicenseURL: this.authForm.rTBusinessLicense.picUrl,
            LegalPersonIDCardOnURL: this.authForm.legalPersonIDCardOn.picUrl,
            LegalPersonIDCardBackURL:
              this.authForm.legalPersonIDCardBack.picUrl,
            BankCardUrl: this.authForm.legalPersonBankCard.picUrl,
            OthersUrl: this.authForm.oherCertificate.picUrl,
            DocRul: "",
          };
          saveGoodsOwnerAuthInfo({ Json: JSON.stringify(params) }).then(
            (res) => {
              this.setFullInfo(2);
            }
          );
        }
      });
    },
    //获取认证信息
    getGoodsOwnerAuthInfo() {
      getGoodsOwnerAuthInfo().then((res) => {
        if (res.enterPrise) {
          this.authForm.businessLicense.picUrl =
            res.enterPrise.BusinessLicenseURL;
          this.authForm.rTBusinessLicense.picUrl =
            res.enterPrise.RTBusinessLicenseURL;
          this.authForm.legalPersonIDCardOn.picUrl =
            res.enterPrise.LegalPersonIDCardOnURL;
          this.authForm.legalPersonIDCardBack.picUrl =
            res.enterPrise.LegalPersonIDCardBackURL;
          this.authForm.legalPersonBankCard.picUrl = res.enterPrise.BankCardUrl;
          this.authForm.oherCertificate.picUrl = res.enterPrise.OthersUrl;
          this.setFullInfo(2);
        }
      });
    },
    //上传法人身份证(正面)
    uploadLegalPersonIDCardOn(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadLegalPersonIDCardOn(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers.idFaceUrl) {
            field.picUrl = res.drivers.idFaceUrl;
          }
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          // field.loading = true
          // field.uploaded = true
        });
    },
    //上传法人身份证(反面)
    uploadLegalPersonIDCardBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadLegalPersonIDCardBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers.idBackUrl) {
            field.picUrl = res.drivers.idBackUrl;
          }
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          // field.loading = true
          // field.uploaded = true
        });
    },
    //上传营业执照(原件三合一正本)
    uploadBusinessLicense(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadBusinessLicense(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.ConsInfo) {
            field.picUrl = res.ConsInfo;
          }
        })
        .catch(() => {})
        .finally(() => {
          field.loading = true;
          field.uploaded = true;
        });
    },
    //上传道路经营许可证
    uploadRtBusinessLicense(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadConveyLicence(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.drivers) {
            field.picUrl = res.drivers;
          }
        })
        .catch(() => {})
        .finally(() => {
          field.loading = true;
          field.uploaded = true;
        });
    },
    //上传企业银行卡
    uploadLegalPersonBankCard(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadLegalPersonBankCard(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.ConsInfo) {
            field.picUrl = res.ConsInfo;
          }
        })
        .catch(() => {})
        .finally(() => {
          field.loading = true;
          field.uploaded = true;
        });
    },
    //上传其他证件
    uploadOherCertificate(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadOherCertificate(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          if (res.ConsInfo) {
            field.picUrl = res.ConsInfo;
          }
        })
        .catch(() => {})
        .finally(() => {
          field.loading = true;
          field.uploaded = true;
        });
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
  },
  created() {
    this.getGoodsOwnerAuthInfo();
  },
  components: {
    UploadImg,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";
.authInfoPage {
  .top {
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    .el-upload__tip {
      color: $textGray2;
    }
  }
}
</style>
